<template>
  <div class="pageContol classReviewDetail">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置： </em>
          <a href="javascript:;" @click="$router.back()">直播管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">{{
            ctype == 1 ? "直播班级审核" : "班级管理"
          }}</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">详情</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="ovy-a">
          <div style="display: flex; justify-content: space-between">
            <h3>直播课程基本信息</h3>
            <el-button @click="$router.back()" class="bgc-bv" size="mini"
              >返回</el-button
            >
          </div>
          <div>
            <el-form ref="ruleForm" label-width="10rem">
              <el-form-item label="直播课程名称：" class="form-inline">
                <p>{{ ruleForm.liveCourseName }}</p>
              </el-form-item>
              <el-form-item label="直播课程图片：" prop="logoUrl">
                <div class="img-el-upload" style="width: 256px">
                  <el-image
                    :src="ruleForm.logoUrl || require('@/assets/develop.png')"
                    fit="contain"
                  ></el-image>
                </div>
              </el-form-item>
              <el-form-item label="直播课程简介：" prop="courseProfile">
                <p>{{ ruleForm.courseProfile || "无" }}</p>
              </el-form-item>
            </el-form>
          </div>
          <h3 style="padding-bottom: 20px">直播课节目录</h3>
          <el-table
            ref="multipleTable"
            :data="List"
            size="small"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="tableHeader"
            stripe
          >
            <el-table-column
              label="序号"
              align="center"
              type="index"
              :index="indexMethod"
            />
            <el-table-column
              label="直播课节名称"
              align="left"
              show-overflow-tooltip
              prop="liveLessonName"
            />
            <el-table-column
              label="讲师"
              align="left"
              show-overflow-tooltip
              prop="teacherNames"
            />
            <template v-if="!flag">
                <el-table-column
                label="直播时间"
                align="left"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.startTime | moment }} ~
                  {{ scope.row.endTime | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="直播状态"
                align="left"
                show-overflow-tooltip
                prop="liveState"
                width="80"
              >
                <template slot-scope="scope">
                  {{ $setDictionary("LIVE_STATE", scope.row.liveState || "--") }}
                </template>
              </el-table-column>
            </template>
            <template v-else>
                <el-table-column
                  label="计划直播时长"
                  align="left"
                  prop="liveTimeStr"
                  show-overflow-tooltip
                />
                <el-table-column
                  label="计划直播时间"
                  align="left"
                  show-overflow-tooltip
                  min-width="180"
                >
                  <template slot-scope="scope">
                    {{ scope.row.startTime | moment }} ~
                    {{ scope.row.endTime | moment }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="直播状态"
                  align="left"
                  show-overflow-tooltip
                  prop="liveState"
                  width="80"
                >
                  <template slot-scope="scope">
                    {{ $setDictionary("LIVE_STATE", scope.row.liveState || "--") }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="实际直播时间"
                  align="left"
                  show-overflow-tooltip
                  min-width="180"
                >
                  <template slot-scope="scope">
                    {{ scope.row.startTimeActual | moment }} ~
                    <template v-if="scope.row.liveState=='20'&&scope.row.startTimeActual">
                      未结束
                    </template>
                    <template v-else>
                      {{ scope.row.endTimeActual | moment }}
                    </template>
                  </template>
                </el-table-column>
                <el-table-column
                  label="实际直播时长"
                  align="left"
                  prop="actualLiveTimeStr"
                  show-overflow-tooltip
                />
            </template>
            <el-table-column
              label="创建时间"
              align="left"
              show-overflow-tooltip
              prop="createTime"
              width="150"
            >
              <template slot-scope="scope">
                {{ scope.row.createTime | moment }}
              </template>
            </el-table-column>
            <Empty slot="empty" />
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import List from "@/mixins/List";
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
export default {
  name: "seeClassMinsCtr",
  components: {
    Empty,
  },
  mixins: [List],
  data() {
    return {
      ruleForm: {},
      // 值为：zbbjsh 直播班级审核；否则-直播课程审核
      ctype: null,
      List:[],
    };
  },
  created() {
    if (this.$route.query.type && this.$route.query.type == "zbbjsh") {
      this.ctype = 1;
    }
    this.liveCourseId = this.$route.query.liveCourseId;
    this.flag = this.$route.query.flag;
    this.getInfo(this.liveCourseId);
  },
  mounted() {},
  methods: {
    //获取直播课程基本信息数据
    getInfo(liveCourseId) {
      this.$post("/biz/live/course/getInfo", { liveCourseId }, 3000, true, 3)
        .then((ret) => {
          this.ruleForm = {
            ...ret.data,
          };
          this.getDatasall()
        })
        .catch((err) => {
          return;
        });
    },
    // getData(pageNum = 1) {
    //   const params = {
    //     pageNum: pageNum,
    //     pageSize: this.pageSize,
    //     liveCourseId: this.$route.query.liveCourseId,
    //   };
    //   if (this.liveLessonName) {
    //     params.liveLessonName = this.liveLessonName;
    //   }
    //   if (this.liveState) {
    //     params.liveState = this.liveState;
    //   }
    //   this.doFetch(
    //     {
    //       url: "/biz/live/lesson/queryLessonList",
    //       params,
    //       pageNum,
    //     },
    //     true,
    //     3
    //   );
    // },
    getDatasall() {
      this.$post(
        "/liveProject/live-project/lessonPageList",
        {
          liveCourseId: this.$route.query.liveCourseId,
        },
        3000,
        true,
        3
      )
        .then((ret) => {
          if (ret.status == "0") {
            this.List = ret.data.list || [];
          }
        })
        .catch((err) => {
          return;
        });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= +290 + 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less">
.imgbox {
  .el-form-item__content {
    width: 256px !important;
    height: 156px;
  }
}
.form-inline {
  width: 45%;
  p {
    width: 20rem;
  }
}
.classReviewDetail {
  .lessNumcount {
    .el-icon-arrow-down:before {
      content: "\e6df" !important;
    }
  }
}
.el-image {
  display: block;
}

.imgBoxs {
  display: flex;
  > img {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    z-index: 1;
  }
}
</style>
<style lang="less" scoped>
.Basicinformation {
  display: flex;
  flex-wrap: wrap;
  > div {
    width: 50%;
    padding: 10px 0;
  }
  .information {
    display: flex;
    flex-wrap: wrap;
    .information-item {
      width: 50%;
    }
  }
}
.lessonNum {
  display: flex;
  padding: 1rem;
  span {
    padding: 0 1rem;
  }
}
.lessNumcount {
  display: flex;
  align-items: center;
  padding: 0 2rem;
  span {
    width: 10rem;
  }
}
.custom-tree-node {
  display: flex;
  align-items: center;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 40rem;
  font-size: 14px;
}
.ellipsiss {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 39.85rem;
  font-size: 14px;
}
.bottom-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-tree-node {
  .el-form-item {
    margin-bottom: 0px;
  }
}
.qualificationImageId {
  .el-image {
    display: flex;
    height: 500px;
  }
}
.dataqualificationImageId {
  margin-left: -2px;
}
.imgUpload {
  display: flex;
  justify-content: center;
}
</style>